/* eslint-disable */
import axios from 'axios';
import isEqual from 'lodash/isEqual';
import { v4 as uuidv4 } from 'uuid';

import Formatters from './formatters';

axios.defaults.baseURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH;
axios.defaults.withCredentials = true;
axios.defaults.params = {};
axios.defaults.params['lang'] = 'fr';

if (process.env.VUE_APP_AUTH_DISABLED) {
  axios.defaults.headers.common['SANCTUM-DISABLED'] = true;
}

axios.interceptors.request.use(function (config) {
  if (config.method === 'get') {
    const hasLangParam = config.url.includes('lang=');
    if (!hasLangParam) {
      const index = config.url.indexOf('?');
      config.url = `${config.url}${index >= 0 ? '&' : '?'}lang=fr`;
    }
  }

  if (config.url.match('backup')) {
    config.baseURL = config.baseURL.replace('/admin', '');
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

// Instance for TUA calls
const tuaAxios = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}api/`
});

const staticAxios = axios.create({
  baseURL: 'https://static.trouveunavocat.be/wp-json/acf/v3/pages/'
});

const newAxios = axios.create({
  baseURL: 'https://staging-api-spartiate.symplicy.be/'
});

if (process.env.VUE_APP_AUTH_DISABLED) {
  delete staticAxios.defaults.headers.common['SANCTUM-DISABLED'];
}


// Cache
const deleteCache = async () => {
  try {
    const request = await axios.get('/clear-all-cache');
    return {
      status: 'success'
    }
  } catch (error) {
    return {
      status: 'error',
      data: error
    }
  }
}

// Lawyers
const getLawyers = async ({ page, items, filter }) => {
  try {
    const params = {
      page,
      'items-per-page': items
    };

    if (filter) {
      params.filter = filter;
    }

    const request = await axios.get('lawyers', { params });
    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Lawyer
const getLawyer = async ({ slug }) => {
  try {
    const request = await axios.get(`lawyers/${slug}`);
    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error',
      data: error
    }
  }
};

const createLawyer = async (lawyer) => {
  const datas = {
    firstname: lawyer.firstname,
    lastname: lawyer.lastname,
    sex: lawyer.sex || '',
    email: lawyer.email || '',
    phone: lawyer.phone || '',
    mobile: lawyer.mobile || '',
    fax: lawyer.fax || '',
    fees: lawyer.fees || '',
    business_hours: lawyer.business_hours || '',
    verified: lawyer.verified,
    does_pro_deo: lawyer.does_pro_deo,
    is_tua_visible: lawyer.is_tua_visible,
    // skills: lawyer.skills_seeding_string || ''
    skill_ids: lawyer.skills.map(s => s.id)
  };

  if (lawyer.addresses && lawyer.addresses.length > 0) {
    datas.addresses = lawyer.addresses.map(el => el.formatted_string);
  }

  if (lawyer.degrees && lawyer.degrees.length > 0) {
    datas.degrees = lawyer.degrees.map(el => el.title);
  }

  if (lawyer.languages && lawyer.languages.length > 0) {
    datas.languages = lawyer.languages.map(el => el.id);
  }

  if (lawyer.links && lawyer.links.length > 0) {
    datas.links = lawyer.links.map(link => {
      return {
        href: link.href || link.link,
        name: link.name
      }
    });
  }

  if (lawyer.bars && lawyer.bars.length > 0) {
    datas.bars = lawyer.bars.map(el => el.id);
  }

  try {
    const request = await axios.post('lawyers/create', datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const saveLawyer = async (lawyer, startLawyer) => {
  const datas = {
    firstname: lawyer.firstname,
    lastname: lawyer.lastname,
    sex: lawyer.sex || '',
    email: lawyer.email || '',
    phone: lawyer.phone || '',
    mobile: lawyer.mobile || '',
    fax: lawyer.fax || '',
    fees: lawyer.fees || '',
    business_hours: lawyer.business_hours || '',
    verified: lawyer.verified,
    does_pro_deo: lawyer.does_pro_deo,
    is_tua_visible: lawyer.is_tua_visible,
    // skills: lawyer.skills_seeding_string || '',
    skill_ids: lawyer.skills.map(s => s.id)
  };

  if (!isEqual(lawyer.addresses, startLawyer.addresses)) {
    datas.addresses = lawyer.addresses.map(el => el.formatted_string);
  }

  if (!isEqual(lawyer.bars, startLawyer.bars)) {
    datas.bars = lawyer.bars.map(el => el.id);
  }

  if (!isEqual(lawyer.degrees, startLawyer.degrees)) {
    datas.degrees = lawyer.degrees.map(el => el.title);
  }

  if (!isEqual(lawyer.links, startLawyer.links)) {
    datas.links = lawyer.links.map(link => {
      return {
        href: link.href || link.link,
        name: link.name
      }
    });
  }

  if (!isEqual(lawyer.languages, startLawyer.languages)) {
    datas.languages = lawyer.languages.map(el => el.id);
  }

  try {
    const request = await axios.post(`lawyers/update/${lawyer.id}`, datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const deleteLawyer = async (id) => {
  try {
    const request = await axios.delete(`lawyers/delete/${id}`);
    const { data } = request;

    return {
      status: 'success',
      data: data.success
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

const changeLawyerPicture = async (pic, id) => {
  const form = new FormData();
  form.append('picture', pic);

  try {
    const request = await axios.post(`lawyers/update/${id}/picture`, form);
    const { data } = request;

    return {
      status: 'success',
      data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const deleteLawyerPicture = async (id) => {
  try {
    const request = await axios.delete(`lawyers/update/${id}/picture`);
    const { data } = request;

    return {
      status: 'success',
      data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

// Clients
const getClients = async ({ page, items, filter }) => {
  try {
    const params = {
      page,
      'items-per-page': items
    };

    if (filter) {
      params.filter = filter;
    }

    const request = await axios.get('clients', { params });
    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Client
const getClient = async ({ uuid }) => {
  try {
    const request = await axios.get(`clients/${uuid}`);
    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error',
      data: error
    }
  }
};

const createClient = async (client) => {
  const datas = {
    name: client.name,
    email: client.email,
    email_box: client.email_box,
    mail_system: client.mail_system,
    subscribed_until: client.subscribed_until,
    lawyer_ids: client.lawyers.map(l => l.id),
    custom_style_iframe_css: client.custom_style_iframe_css,
    custom_style_popup_css: client.custom_style_popup_css
  };

  try {
    const request = await axios.post('clients/create', datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const saveClient = async (client) => {
  const datas = {
    name: client.name,
    email: client.email,
    email_box: client.mail_system === 2 ? '' : client.email_box,
    mail_system: client.mail_system,
    subscribed_until: client.subscribed_until,
    lawyer_ids: client.lawyers.map(l => l.id),
    custom_style_iframe_css: client.custom_style_iframe_css,
    custom_style_popup_css: client.custom_style_popup_css
  };

  try {
    const request = await axios.post(`clients/update/${client.uuid}`, datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const deleteClient = async (uuid) => {
  try {
    const request = await axios.delete(`clients/delete/${uuid}`);
    const { data } = request;

    return {
      status: 'success',
      data: data.success
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

const createAccounts = async (uuid) => {
  try {
    const request = await axios.get(`clients/${uuid}/send-spartiate-mails`);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

// Lawcases
const getLawcases = async ({ page, items }) => {
  try {
    const request = await tuaAxios.get(`lawCases?lang=fr&page=${page ? page : 1}&items-per-page=${items ? items : 20}&penal=true`);
    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

const searchLawcases = async ({ filter }) => {
  try {
    const request = await tuaAxios.post('search', {
      lang: 'fr',
      term: filter,
      penal: true
    });

    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Lawcase
const getLawcase = async ({ id }) => {
  try {
    const request = await axios.get(`/cases/${id}/`);
    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error',
      error
    }
  }
};

const createLawcase = async (lawcase) => {
  const datas = Formatters.lawcase.to(lawcase);

  try {
    const request = await axios.post('cases/create', datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const saveLawcase = async (lawcase) => {
  const datas = Formatters.lawcase.to(lawcase);

  try {
    const request = await axios.post(`cases/update/${lawcase.id}`, datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const deleteLawcase = async (id, deleteForm) => {
  try {
    const request = await axios.post(`cases/delete/${id}`, {
      delete_forms: deleteForm
    });
    const { data } = request;

    return {
      status: 'success',
      data: data.success
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Skills
const getSkills = async ({ page, items, filter }) => {
  try {
    const params = {
      page,
      'items-per-page': items
    };

    if (filter) {
      params.filter = filter;
    }

    const request = await axios.get('skills', { params });
    const { data } = request;
    data.data.map(skill => {
      skill.cat = skill.category ? skill.category.description : '-'
      return skill;
    });

    return {
      status: 'success',
      data
    }
  } catch (error) {
    return {
      status: 'error'
    }
  }
};

const searchSkills = async ({ term }) => {
  try {
    const request = await axios.post('skills/search', { term, lang: 'fr' });
    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Skill
const getSkill = async ({ id }) => {
  try {
    const request = await axios.get(`/skills/${id}/?penal=true`);
    const { data } = request;

    return {
      status: 'success',
      data: Formatters.skill.from(data.data)
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

const createSkill = async (skill) => {
  const datas = Formatters.skill.to(skill);

  try {
    const request = await axios.post('skills/create', datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const saveSkill = async (skill) => {
  const datas = Formatters.skill.to(skill);

  try {
    const request = await axios.post(`skills/update/${skill.id}`, datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const deleteSkill = async (id) => {
  try {
    const request = await axios.delete(`skills/delete/${id}`);
    const { data } = request;

    return {
      status: 'success',
      data: data.success
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Categories
const getCategories = async ({ page, items, filter }) => {
  try {
    const params = {
      page,
      'items-per-page': items
    };

    if (filter) {
      params.filter = filter;
    }

    const request = await axios.get('categories', { params });
    const { data } = request;
    data.data = data.data.map(c => {
      c.name = c.description;
      return c;
    });

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error',
      data: error
    }
  }
};

const getAllCategories = async () => {
  try {
    const request = await axios.get('categories/all');
    const { data } = request;
    data.data = data.data.map(c => {
      c.name = c.description;
      return c;
    });

    return {
      status: 'success',
      data
    }
  } catch (error) {
    return {
      status: 'error',
      data: error
    }
  }
};

// Category
const getCategory = async ({ id }) => {
  try {
    const request = await axios.get(`/categories/${id}/`);
    const { data } = request;

    return {
      status: 'success',
      data
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

const createCategory = async (category) => {
  const datas = Formatters.category.to(category);

  try {
    const request = await axios.post('categories/create', datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const saveCategory = async (category) => {
  const datas = Formatters.category.to(category);

  try {
    const request = await axios.post(`categories/update/${category.id}`, datas);
    const { data } = request;

    return {
      status: 'success',
      data: data
    }
  } catch (e) {
    return {
      status: 'error'
    }
  }
};

const deleteCategory = async (id) => {
  try {
    const request = await axios.delete(`categories/delete/${id}`);
    const { data } = request;

    return {
      status: 'success',
      data: data.success
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Categorie and skills
const getSkillsAndCategories = async (params) => {
  // params = { page, items, filter }
  try {
    const skillsRequest = await getSkills(params);
    const categoriesRequest = await getCategories(params);

    const skills = skillsRequest.data.data.map(skill => {
      skill.cat = skill.category ? skill.category.description : '-'
      return skill;
    });

    const categories = categoriesRequest.data.data.map(c => {
      c.isCat = true;
      c.name = c.description;
      return c;
    });

    return {
      status: 'success',
      data: {
        data: skills.concat(categories)
      }
    }
  } catch (error) {
    return {
      status: 'error',
      data: error
    }
  }
}

// Forms
const getForms = async ({ page, items, filter }) => {
  try {
    const params = {
      page,
      'items-per-page': items
    };

    if (filter) {
      params.filter = filter;
    }

    const request = await axios.get('forms', { params });
    const { data } = request;
    data.data = data.data.map(f => {
      f.name = f.name || f.path;
      return f;
    });

    return {
      status: 'success',
      data
    }
  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Form
const getForm = async ({ uuid }) => {
  try {
    const request = await axios.get(`forms/${uuid}`);
    const { data } = request;

    // Meta
    data.data.content.meta.path = data.data.path;

    if (data.data.case) {
      data.data.content.meta.case = {
        ...data.data.case,
        content: data.data.case.content_fr
      };

      data.data.content.meta.case_id = data.data.case.id;
    }


    if (data.data.client) {
      data.data.content.meta.client = data.data.client;
      data.data.content.meta.client_uuid = data.data.client.uuid;
    }

    // Questions
    data.data.content.data = Formatters.questions.from(data.data.content.data);

    return {
      status: 'success',
      data: data.data
    }
  } catch (error) {
    return {
      status: 'error',
      data: error
    }
  }
};

const createForm = async ({ meta, data }) => {
  const content = {
    meta: {
      uuid: uuidv4(),
      name: meta.name,
      case_id: meta.case_id,
      client_uuid: meta.client_uuid
    },
    data: Formatters.questions.to(data)
  };

  try {
    const request = await axios.post('forms/create', { content });
    const { data } = request;

    return {
      status: 'success',
      data: data
    }

  } catch (error) {
    if (error.response.status === 480) {
      return {
        status: error.response.status,
        data: error.response.data.message
      }
    }
    return {
      status: 'error',
      data: error
    }
  }
};

const updateForm = async ({ uuid, meta, data }) => {
  const content = {
    meta: {
      uuid,
      name: meta.name,
      case_id: meta.case_id,
      client_uuid: meta.client_uuid,
      tags: meta.tags
    },
    data: Formatters.questions.to(data)
  };

  console.log('content sent :', content);

  if (content.meta.uuid === '3dc5fbe8-4c1f-42c2-bd98-6d2b8bc8a6cb') {
    content.meta.default = true;
  }

  try {
    const request = await axios.post(`forms/update/${uuid}`, { content });
    const { data } = request;

    return {
      status: 'success',
      data: data.success
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

const deleteForm = async (uuid) => {
  try {
    const request = await axios.delete(`forms/delete/${uuid}`);
    const { data } = request;

    return {
      status: 'success',
      data: data.success
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

// Backups
const downloadForms = async () => {
  try {
    const request = await axios.get('backup/forms', { responseType: 'arraybuffer' });

    return {
      status: 'success',
      data: request
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
}

const downloadDocuments = async () => {
  try {
    const request = await axios.get('backup/documents', { responseType: 'arraybuffer' });

    return {
      status: 'success',
      data: request
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
}

const downloadBackup = async () => {
  try {
    const request = await axios.get('backup/last-backup');

    return {
      status: 'success',
      data: request
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
}

const downloadBackupFiles = async () => {
  try {
    const request = await axios.get('backup/profile-pictures', { responseType: 'arraybuffer' });

    return {
      status: 'success',
      data: request
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};

const downloadBackupFormFiles = async () => {
  try {
    const request = await axios.get('backup/form-answer-files', { responseType: 'arraybuffer' });

    return {
      status: 'success',
      data: request
    }

  } catch (error) {
    return {
      status: 'error'
    }
  }
};


// Static pages
const staticPage = async (id) => {
  try {
    const request = await staticAxios.get('18');

    return {
      static: 'success',
      data: request.data.acf
    }
  } catch (error) {
    return {
      status: 'error',
      data: error
    }
  }
}



export {
  deleteCache,
  // Lawyers
  getLawyers,
  // Lawyer
  getLawyer,
  saveLawyer,
  createLawyer,
  deleteLawyer,
  deleteLawyerPicture,
  changeLawyerPicture,
  // Clients
  getClients,
  // Client
  getClient,
  saveClient,
  createClient,
  deleteClient,
  createAccounts,
  // Lawcases
  getLawcases,
  searchLawcases,
  // Lawcase
  getLawcase,
  saveLawcase,
  createLawcase,
  deleteLawcase,
  // Skills
  getSkills,
  searchSkills,
  // Skill
  getSkill,
  saveSkill,
  createSkill,
  deleteSkill,
  // Categories
  getCategories,
  getAllCategories,
  // Category
  getCategory,
  saveCategory,
  createCategory,
  deleteCategory,
  // Skills & cat
  getSkillsAndCategories,
  // Forms
  getForms,
  // Form
  getForm,
  createForm,
  updateForm,
  deleteForm,
  // Static page
  staticPage,
  downloadForms,
  downloadDocuments,
  downloadBackup,
  downloadBackupFiles,
  downloadBackupFormFiles
};
