<template>
  <div class="question">
    <div class="question-aside">
      <span class="question-index">{{ index + 1 }}.</span>
      <div class="question-actions">
        <btn
          :theme="'icon invisible'"
          @click="$emit('delete')"
        >
          <sprite
            :type="'trash'"
            :width="20"
            :height="20"
          />
        </btn>
        <btn
          :theme="'icon invisible'"
          @click="$emit('add', index)"
        >
          <sprite
            :type="'plus'"
            :width="16"
            :height="16"
          />
        </btn>
      </div>
    </div>

    <div class="question-content">
      <div
        v-if="question.title"
        class="question-row"
        data-column="1"
      >
        <div class="question-column">
          <inputs
            :id="`${question.id}-title`"
            :value="question.title[lang]"
            :type="'textarea'"
            :lineBreak="false"
            :expand="true"
            :placeholder="$t('placeholders.add_title')"
            :theme="'ghost big'"
            @input="onTitle"
          />
        </div>

        <div
          v-if="question.description"
          class="question-column"
        >
          <inputs
            :id="`${question.id}-desc`"
            :value="question.description[lang]"
            :type="'textarea'"
            :lineBreak="false"
            :expand="true"
            :theme="'ghost faded'"
            :placeholder="$t('placeholders.add_description')"
            @input="onDescription"
          />
        </div>
      </div>

      <div
        class="question-row"
        data-column="2"
      >
        <!-- Type / answers -->
        <div class="question-column">
          <selection
            :id="`${question.id}-type`"
            :options="types"
            :selecteds="selectedType"
            :label="'Type'"
            @onOption="onType"
          />

          <div
            v-if="question.type === 'checkbox' || question.type === 'radio'"
            class="question-answers"
          >
            <div
              v-for="(answer, ind) in question.answers"
              :key="`${question.id}-answer-${answer.value}`"
              :class="`question-answer ${question.type} ${answer.tagsVisible ? 'is-open' : ''}`"
            >
              <inputs
                :id="`${question.id}-answer-${answer.value}`"
                :value="answer.label[lang]"
                :type="'textarea'"
                :lineBreak="false"
                :expand="true"
                :theme="question.type === 'checkbox' ? 'ghost' : 'plain'"
                :placeholder="ind === question.answers.length - 1 ? $t('placeholders.add_answer') : $t('placeholders.answer', { index: ind + 1 })"
                @input="onAnswer($event, ind, 'label')"
                @focus="onAnswerFocus(ind)"
              />

              <div
                v-if="answer.tagsVisible"
                class="question-answer-tags"
              >
                <form
                  @submit.prevent="addTag(ind)"
                  class="question-answer-tags-form"
                >
                  <Inputs
                    :id="`${question.id}-desc`"
                    :value="answer.tagToAdd"
                    :theme="'small faded'"
                    :placeholder="'Ajouter un tag'"
                    @input="onTagToAdd($event, ind)"
                  />

                  <button
                    class="question-answer-tags-form-submit"
                    :disabled="!answer.tagToAdd || !answer.tagToAdd.length"
                  >
                    <Sprite
                      :type="'plus'"
                      :width="14"
                      :height="14"
                    />
                  </button>
                </form>
                <ul class="question-answer-tags-list">
                  <li
                    v-for="(tag, prop) in answer.tags"
                    :key="`${question.id}-answer-${answer.value}-${prop}`"
                  >
                    <Btn
                      :class="[
                        'pill',
                        { 'pill-success': tag.type === 'add' },
                        { 'pill-error': tag.type === 'remove' },
                      ]"
                      @click="$emit('updateTagType', { tag: prop, index: ind })"
                    >
                      {{ prop }}
                      <Sprite
                        :type="'close'"
                        :width="12"
                        :height="12"
                        @click.native.stop="$emit('removeTag', { tag: prop, index: ind })"
                      />
                    </Btn>
                  </li>
                </ul>
              </div>

              <button
                class="question-answer-toggle"
                @click="$emit('toggleTags', ind)"
              >
              </button>

              <button
                v-if="ind !== question.answers.length - 1"
                class="question-answer-delete"
                @click="$emit('deleteAnswer', ind)"
              >
                <sprite :type="'trash'"/>
                <span class="u-visually-hidden">{{ $t('placeholders.delete_answer') }}</span>
              </button>
            </div>
          </div>
        </div>

        <!-- Dependencies -->
        <div
          v-if="index !== 0"
          class="question-column question-dependencies"
        >
          <span class="question-column-label">{{ $t('labels.display_conditions') }}</span>
          <ul
            v-if="dependencies && dependencies.length > 0"
            class="question-rules"
          >
            <li
              v-for="(dep, i) in dependencies"
              :key="`${question.id}-rule-${i}`"
              class="question-rule"
            >
              <div class="question-rule-actions">
                <!-- <span class="question-rule-index">{{ i + 1 }}.</span> -->
                <btn
                  :theme="'icon invisible'"
                  @click="$emit('deleteRule', i)"
                >
                  <sprite :type="'trash'"/>
                </btn>
                <btn
                  :theme="'icon invisible'"
                  @click="$emit('editRule', {
                    question: dep.questionID,
                    answers: dep.answers.map(a => a.value),
                    index: dep.index
                  })"
                >
                  <sprite
                    :type="'edit'"
                    :width="12"
                    :height="12"
                  />
                </btn>
              </div>

              <div class="question-rule-content">
                <span class="question-rule-question">{{ dep.question }}</span>

                <ul class="question-rule-answers">
                  <li
                    v-for="(answer, j) in dep.answers"
                    :key="`${question.id}-rule-${i}-value-${j}`"
                    class="question-rule-answer"
                  >
                    {{ answer.label }}
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <p
            v-else
            class="question-dependencies-none"
          >
            {{ $t('globals.none') }}
          </p>

          <div class="question-dependencies-actions">
            <btn
              :theme="'icon invisible'"
              @click="addRule"
            >
              <sprite :type="'plus'" />
            </btn>
          </div>
        </div>

        <!-- Integration -->
        <div class="question-column">
          <Inputs
            :id="`${question.id}-integration`"
            :value="question.integration"
            :label="$t('labels.integration')"
            @input="onIntegration($event)"
          />
        </div>
      </div>
    </div>

    <ul class="question-langs">
      <li
        v-for="lng in langs"
        :key="`${index}-lang-${lng}`"
        class="question-langs-item"
      >
        <button
          :class="['question-langs-button', { 'is-current': lang === lng }]"
          @click="updateLang(lng)"
        >
          {{ lng }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import Btn from '@/components/components/Btn';
import Sprite from '@/components/components/Sprite';
import Inputs from '@/components/components/Inputs';
import Selection from '@/components/components/Selection';

export default {
  components: {
    Inputs, Selection, Btn, Sprite
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    questions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      lang: 'fr',
      langs: [ 'fr', 'en', 'nl' ],
      types: [
        { name: 'Text', id: 'text' },
        { name: 'Number', id: 'number' },
        { name: 'Date', id: 'date' },
        { name: 'Textarea', id: 'textarea' },
        { name: 'Checkbox', id: 'checkbox' },
        { name: 'Radio', id: 'radio' },
        { name: 'File', id: 'file' }
      ]
    }
  },
  computed: {
    selectedType() {
      const selected = this.types.filter(t => t.id === this.question.type);
      return [selected[0]];
    },
    dependencies() {
      if (!this.question.visibility) {
        return [];
      }

      const deps = [];

      for (let i = 0; i < this.question.visibility.rules.length; i++) {
        const rule = this.question.visibility.rules[i];
        const question = this.questions.filter(q => q.id === rule.question);

        if (question.length > 0) {
          const dep = {
            questionID: question[0].id,
            question: question[0].title[this.lang],
            index: i,
            answers: []
          };

          for (let j = 0; j < rule.values.length; j++) {
            const value = rule.values[j];
            const answer = question[0].answers.filter(a => a.value === value);

            if (answer.length > 0) {
              dep.answers.push({
                label: answer[0].label[this.lang],
                value: answer[0].value
              });
            }
          }

          deps.push(dep);
        }
      }

      return deps;
    }
  },
  methods: {
    onTitle(e) {
      this.$emit('title', {
        value: e,
        lang: this.lang
      });
    },
    onDescription(e) {
      this.$emit('description', {
        value: e,
        lang: this.lang
      });
    },
    onIntegration(value) {
      this.$emit('integration', value);
    },
    onType(e) {
      this.$emit('type', e.id);
    },
    onAnswer(e, index, type) {
      this.$emit('answer', {
        value: e,
        lang: this.lang,
        index, type
      });
    },
    onAnswerFocus(index) {
      if (index !== this.question.answers.length - 1) {
        return;
      }

      this.$emit('addAnswer');
    },
    onTagToAdd(value, index) {
      this.$emit('inputTag', { value, index });
    },
    addTag(index) {
      this.$emit('addTag', index);
    },
    addRule() {
      this.$store.commit('form/addRule', this.index);
      this.$emit('editRule', {
        question: null,
        answers: [],
        index: this.question.visibility.rules.length - 1
      })
    },
    // removeTag(e, data) {


    // },
    updateLang(lang) {
      this.lang = lang;
    }
  }
};
</script>

<style lang="scss" scoped>
.question {
  display: flex;
  align-items: flex-start;

  &:hover {
    .question-actions {
      opacity: 1;
      pointer-events: all;
    }
  }

  // Aside
  // -----------------------------------------------------------------------------
  &-aside {
    flex-shrink: 0;
    width: 40px;
  }

  &-index {
    @include padding(3px null null);
    display: block;
    color: var(--grey-dark);
    font-size: rem(20px);
    font-weight: 600;
    line-height: 1;
  }

  &-actions {
    @include margin(8px null null -8px);
    transition: opacity .3s $ease-out-quart;
    opacity: 0;
    pointer-events: none;

    .button {
      height: 32px;
    }
  }

  // Content
  // -----------------------------------------------------------------------------
  &-content {
    width: calc(100% - 80px);
  }

  &-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    column-gap: 60px;
    row-gap: 12px;

    &[data-column="1"] {
      grid-template-columns: 1fr;
    }

    &[data-column="2"] {
      grid-template-columns: repeat(2, 1fr);
    }

    + .question-row {
      @include margin(24px null null);
    }
  }

  &-column {
    width: 100%;
    max-width: 860px;

    &:nth-child(n + 3) {
      margin-top: 20px;
    }

    &-label {
      @include padding(null null 10px);
      display: block;
      color: var(--grey);
      font-size: rem(14px);
      line-height: 1;
    }

    > .button {
      width: 100%;
    }
  }

  // Answers
  &-answers {
    @include margin(12px null null);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-answer {
    @include padding(6px null);
    display: block;
    position: relative;

    &:hover {
      .question-answer-delete {
        opacity: 1;
        pointer-events: all;
      }
    }

    &.is-open {
      .question-answer-toggle {
        &::after {
          @include triangle(10px, var(--grey-lightest), up);
          border-top: 0;
        }
      }
    }

    > .input {
      width: calc(100% - 40px);
    }

    &.checkbox {
      @include padding(null null null 32px);
      width: 100%;

      &::before {
        @include position(absolute, 9px null null 1px);
        @include size(16px);
        content: '';
        border: 1px solid var(--primary);
      }

      .question-answer-delete {
        height: 32px;
      }
    }

    &.radio {
      width: 100%;

      .question-answer-toggle {
        top: 14px;
      }
    }

    &-tags {
      @include padding(4px null null);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &-form {
        display: flex;
        width: 40%;
        position: relative;

        &-submit {
          @include padding(null 10px);
          @include position(absolute, 0 0 null null);
          position: absolute;
          z-index: 1;
          height: 100%;
          border-radius: 0 4px 4px 0;

          &:not([disabled]) {
            cursor: pointer;
          }

          &[disabled] {
            .svg {
              fill: var(--white-darker);
            }
          }
        }
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
        width: calc(60% - 12px);

        li {
          @include margin(null 4px 4px);
          // margin: 4px;
        }
      }
    }

    &-toggle {
      @include position(absolute, 0 0 null null);
      @include size(40px, 30px);
      cursor: pointer;

      &::after {
        @include position(absolute, calc(50% - 2px) 15px null null);
        @include triangle(10px, var(--grey-lightest), down);
        content: '';
        transition: border-top-color .3s $ease-out-quart, border-bottom-color .3s $ease-out-quart;
      }
    }

    &-delete {
      @include position(absolute, 0 100% null null);
      @include size(32px, 100%);
      transition: opacity .2s $ease-out-quart;
      opacity: 0;
      cursor: pointer;
      outline: none;

      &:hover {
        .svg {
          fill: var(--black);
        }
      }

      .svg {
        @include position(absolute, 50% null null 50%);
        transform: translate(-50%, -50%);
        transition: fill .3s $ease-out-quart;
        fill: var(--grey-lighter);
      }
    }
  }

  // Dependencise
  &-dependencies {
    position: relative;

    &:hover {
      .question-dependencies-actions {
        opacity: 1;
      }
    }

    &-none {
      @include margin(12px null null);
      color: var(--grey-lighter);
      font-size: rem(18px);
    }

    &-actions {
      @include position(absolute, 100% null null 0);
      @include size(100%, auto);
      transition: opacity .2s $ease-out-quart;
      opacity: 0;

      .button {
        width: 100%;
      }
    }
  }

  // Rules
  &-rules {
    width: 100%;

    + .button {
      @include margin(12px null null);
    }
  }

  &-rule {
    position: relative;
    width: 100%;

    &:hover {
      .question-rule-actions {
        opacity: 1;
        pointer-events: all;
      }
    }

    // Aside
    &-actions {
      @include position(absolute, -4px 100% null null);
      transition: opacity .2s $ease-out-quart;
      opacity: 0;

      .button {
        @include size(40px, 26px);
      }
    }

    &-index {
      color: var(--grey);
    }

    // Content
    &-content {
      width: calc(100% - 30px);
    }

    &-question {
      color: var(--black);
      font-size: rem(18px);
      font-weight: 600;
    }

    &-answers {
      @include margin(8px null null);
    }

    &-answer {
      @include padding(null null null 16px);
      position: relative;
      color: var(--grey);

      &::before {
        @include position(absolute, 9px null null 0);
        @include size(8px, 1px);
        content: '';
        background-color: var(--grey);
      }

      &:not(:first-child) {
        margin-top: 4px;
      }
    }
  }

  // Lang
  // -----------------------------------------------------------------------------
  &-langs {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    border-left: 1px solid var(--grey-lightest);
    width: 40px;

    &-button {
      @include padding(8px);
      display: block;
      position: relative;
      width: 100%;
      transition: color .3s $ease-out-quart;
      border: 1px solid var(--white);
      border-left: 0;
      color: var(--grey-light);
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      outline: none;

      &:hover {
        color: var(--primary);
      }

      &.is-current {
        color: var(--black);
        border-color: var(--grey-lightest);

        &::before {
          @include size(1px, 100%);
          @include position(absolute, 0 null null -1px);
          content: '';
          background-color: var(--white);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.question {
  .question-answers {
    .question-answer-tags-form {
      .input {
        input {
          padding-right: 40px;
        }
      }
    }
  }
}
</style>